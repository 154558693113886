html {
    height: 100%;
}
body {
    font-family: 'Open Sans', sans-serif;
    background: url('../../images/background.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100%;
    padding-top: 6%;
    padding-bottom: 5%;
    font-size: 14px;
}
.box {
    background: #ffffff;
    border: 1px solid #ddd;
    width: 400px;
    border-top: 5px solid #128bc6;
    margin: auto;
}
.box1 {
    padding: 20px 20px;
}
.box img {
    margin: auto;
}
.box h3 {
    margin-top: 0;
    text-align: center;
}
input {
    border-radius: 0 !important;
}
input[type='submit'] {
    margin-bottom: 10px;
    border: none;
}

.checkbox label {
    padding-left: 0;
}

.animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotateX(-10deg);
    }
    70% {
        -webkit-transform: perspective(400px) rotateX(10deg);
    }
    100% {
        -webkit-transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}
@keyframes flipInX {
    0% {
        transform: perspective(400px) rotateX(90deg);
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotateX(-10deg);
    }
    70% {
        transform: perspective(400px) rotateX(10deg);
    }
    100% {
        transform: perspective(400px) rotateX(0deg);
        opacity: 1;
    }
}
.flipInX {
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    backface-visibility: visible !important;
    animation-name: flipInX;
}

span.help-block {
    color: #a94442 !important;
}
@media (min-width: 320px) and (max-width: 425px) {
    .box {
        width: 100% !important;
    }
}
.form-control {
    font-size: 14px;
}

.help-block {
    color: #a94442 !important;
    font-size: 12px;
}

.has-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.social i {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.social:hover {
    color: white;
}
